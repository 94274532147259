function toUnderscore(string) {
  return string
    .replace(/[^a-zA-Z0-9 _-]/g, '')
    .replace(/([A-Z])/g, '_$1')
    .replace(/[ -]/g, '_')
    .replace(/_+/g, '_')
    .replace(/(^_|_$)/, '')
    .toLowerCase();
}

export default toUnderscore;
